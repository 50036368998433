.side-chat-body {
    position: fixed;
    right: 0;
    top: 0;
    width: 650px;
    height: 100%;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 5;
    display: flex;
    flex-direction: column;
    &.open {
        transform: translateX(0);
        transition: transform 0.2s ease-in-out;
    }
}
.side-chat-body.side-chat-conversations-body {
    width: 50%;
    max-width: 750px;
}
.side-chat-body.side-chat-reviews-body, .side-chat-body.side-chat-body.side-chat-feedback-body {
    width: 550px;
}
.name-number-ctn{
    display: flex;
    flex-direction: row;
    
}
.side-chat-top-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.side-chat-content {
    margin: 30px;
    display: flex;
    flex-direction: column;
    height: 95%;
}

.form-content {
    margin-top: 10px;
    flex: 5;
    overflow-y: auto;
}
.chat-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
}
.back-arrow-ctn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
    margin-top: 10px;
}
.edit-button{
    cursor: pointer;
}
.side-chat-back-arrow{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.guest-name-ctn{
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.block-switch-container {
    margin-left: 50px;
  }
.name-switch-ctn{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.manual-switch-label{
    font-size: small;
}
.manual-switch-container{
    display: flex;
    align-items: center;
}
.google-textbox-ctn{
    margin-top: 20px;
}
.google-stars{
    margin-top: 15px;
    color: rgb(0, 0, 0);
    font-size: 18px;
}
.google-satisfaction-label{
    margin-top: 25px;
    font-weight: 500;
}
.google-guest-phone-number{
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    margin-top: 25px;
    font-size: 14px;
}
.google-guest-email{
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    margin-top: 25px;
    font-size: 14px;
}
.flex-content-sidechat{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.google-review-ctn{
    
    margin-left: 15px;
    margin-top: 25px;
    width: fit-content;
    max-height: 70%;
    overflow-y: auto;
}
.google-review-label{
    font-weight: 500;
    margin-top: 10px;
}
.feedback-message{
    padding: 15px 25px 15px 15px;
    border-radius: 15px;
    background-color: #01a0c6;
    margin-right: 15px;
    margin-top: 10px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 25px;
    width: fit-content;
}
.leads-message{
    font-size: 14px;
    font-weight: 900;
    color: #555;
    line-height: 1.4em;
}
.leads-response-message-container{
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.feedback-response-message, .leads-response-message{
    padding: 10px 15px;
    border-radius: 20px;
    background-color: #ececf4;
    margin-right: 15px;
    margin-top: 10px;
    color: #222;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 25px;
    width: fit-content;
}
.google-response-buttons-ctn.leads{
    margin-top: 25px;
}
#leads-response{
    max-width: 300px;
    flex-wrap: wrap;
}
.response-image{
    width: 100%;
}
.google-review-message{
    padding: 15px 25px 15px 15px;
    border-radius: 15px;
    background-color: #01a0c6;
    margin-right: 15px;
    margin-top: 10px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 25px;
    width: fit-content;
}
.google-review-response-ctn{
    margin-top: 10px;
}
.google-review-response-ctn::before{
    width: fit-content;
}
.google-redview-response-ctn{
    margin-top: 25px;
}
.google-review-response-message{
    padding: 15px 25px 15px 15px;
    border-radius: 15px;
    background-color: #edecec;
    margin-right: 15px;
    margin-top: 10px;
    color: #222;
    font-weight: 500;
    margin-bottom: 40px;
    font-size: 13px;

    line-height: 18px;
    margin-bottom: 10px;
    width: fit-content;
}
.google-review-textbox {
    width: 450px;
    border: none;
    box-sizing: border-box;
    background-color: #e3e3e3;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    line-height: 20px;
    padding: 15px;
    font-size: 13px;
    color: #071B23;
    overflow: auto;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    height: 150px;
    &::placeholder {
      color: #898888;
    }
}
.google-response-buttons-ctn{
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: static;
    align-items: center;
}
.google-gpt-response-button.left{
    background-color: #fff;
    border: 1px solid #01a0c6;
    padding: 10px 25px;
    border-radius: 8px;
    margin-right: 4%;
    color: #01a0c6;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    align-items: center;
}
.google-gpt-response-button.left.disabled:hover{
    cursor:not-allowed;

}
.disable-ctn{
    position: relative;
}
.disable-feature{
    pointer-events: none;
    padding: 0px 10px 10px 10px;
    padding-top: 5px;
}
.disable-feature::before{
    content: "";
    position: absolute;
    margin-top: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(230, 230, 230, 0.5);
    z-index: 10;
    border-radius: 10px;
}
.disable-feature:hover{
    cursor: not-allowed;
}
.disable-icon-ctn{
    z-index: 20;
}
.disable-icon{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    pointer-events: none;
}
.disable-icon img{
    width: 100px;
    height: 100px;
}
.sparkle-icon{
    width: 14px;
}
.google-gpt-response-button.right{
    background-color: #288b37;
    border-radius: 8px;
    padding: 10px 25px;
    color: white;
    margin-right: 15px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    align-items: center;
}
.google-gpt-response-button.right.disabled:hover{
    cursor:not-allowed;
    
}
.google-gpt-response-button.left:hover{
    background-color: #fafafa;
}
.switch.manual-switch{
    margin-right: 0px;
}
.conversation-guest-name{
    margin-right: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #55555d;
}
.conversations-guest-phone-number{
    margin-bottom: 15px;
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    font-size: 14px;
    margin: 0 10px;
}
.arrival-guest-name{
    font-size: x-large;
}
.sidechat-arrival-ctn{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.arrival-room{
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    font-size: 14px;
    margin-left: 25px;
}
.arrival-room.edit-mode{
    background-color: transparent;
}
.arrival-phone-number{
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    margin-top: 15px;
    font-size: 14px;
}
.arrival-checkin-checkout-date{
    background-color: #edecec;
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    margin-top: 10px;
    font-size: 14px;
}
.cancel-arrival-edit{
    cursor: pointer;
    margin-right: 10px;
}