.filters-container {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: flex-end;
  border-bottom: 1px solid #ddd;
}
.filters-ctn{
  display: flex;
  flex-direction: column;
}
.filter-button {
  width: 100px;
  margin-right: 10px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #374151;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #D1D5DB;
  user-select: none;
  box-sizing: border-box;
  height: 35px;
  
  &.selected {
    margin-top: 0px;
    border-color: #01A0C6;
    border-style: solid;
    border-width: 2px;
  }

  &:hover {
    cursor: pointer;
    
    &.incomplete {
      background-color: #fff;
    }

    &.in-progress {
      background-color: #fff;
    }
  
    &.complete {
      background-color: #fff;
    }
  }

  &:active {
    cursor: pointer;
    
    &.incomplete {
      background-color: #fff;
    }

    &.in-progress {
      background-color: #fff;
    }
  
    &.complete {
      background-color: #fff;
    }
  }

  &.incomplete {
    background-color: #fff;
  }

  &.in-progress {
    background-color: #fff;
  }

  &.complete {
    background-color: #fff;
  }
}

.filter-selector{
  border: 1px solid #d1d5d8;
  padding: 9px 13px 9px 17px;
  gap: 8px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 92px;
  position: relative;
}
#filter-label{
  color: #374151;
  font-weight: 500;
}
.filter-dropdown-menu{
  position: fixed;
  top: 100px;
  list-style-type: none;
  background-color: white;
  width: 250px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #d1d5d8;
  font-size: 12px;
  padding: 20px;
  z-index: 1;
}
.cancel-filter{
  display: flex;
  justify-content: flex-end;
  height: 10px;
}
.filter-item{
  margin-left: 0px;
  margin-bottom: 6px;
}
.filter-item-label{
  display: flex;
  align-items: center;
}
.filter-checkbox{
  margin-right: 10px;
  accent-color: #000;
}
.cancel-filter-image{
  cursor: pointer;
}
.selected-filters{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
}
.selected-filter-ctn{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.selected-filter-cell{
  background-color: #eef0f3;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
  padding: 6px 4px 6px 10px;
  border-radius: 20px;
  width: max-content;
  height: 28px;
  gap: 2px;
}
.selected-filter-label{
  margin-right: 10px;
}
.selected-filter{
  display: flex;
  justify-content: flex-end;
  height: 10px;
  margin-right: 10px;
}
.clear-filters-btn-ctn{
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
}
.clear-filters-btn{
  border: 2px solid #e7e5e4;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-filters-btn:hover{
  background-color: #fcfcfc;
}