.requests-panel-body {
  flex: 5;
  height: 100%;
  background-color: #fff;
  margin-bottom: 0px;
  display: flex;
}

.requests-panel-content {
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.requests-panel-title {
  color: #33333D;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px; /* 120% */
  letter-spacing: 0.4px;
  text-align: center;
  display: flex;
  justify-content:flex-start;
}

.requests-panel-divider-line {
  background-color: #0000001f;
  height: 1px;
  margin-top: 40px;
  width: 100%;
}

.requests-list-body {
  // overflow:auto;
  text-align:start;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  height: 80%;
}

.custom-message-button {
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: -15px;
  right: 0;
  &:hover {
    cursor: pointer;
  }
  &:active {
    filter: brightness(75%);
  }
}
.message-icon-ctn{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.message-icon-ctn:hover{
  background-color: #f7f7fa;
}
.message-icon{
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.open-options-ctn{
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  position: absolute;
  top: 15px;
  right: 0px;
}
.open-options{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 5px

}
.option-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  
}