.popup-textbox-container {
    position: relative;
  }
.popup-textbox {
  display: none;
  position: absolute;
  height: 80px;
  width: 197px;
  padding: 4px 12px;
  gap: 10px;
  background-color: #017498;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  z-index: 30;
  cursor: default;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);

  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    text-align: center;
  }

  a {
    color: #fff;
    text-decoration: underline;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;

  }
}

.nav-button:hover .popup-textbox-container .popup-textbox {
  display: block;
  left: 50%;
  transform: translate(0%, -50%);
}

.disable-body:hover + .popup-textbox-container .popup-textbox,
.popup-textbox-container:hover .popup-textbox,
.popup-textbox:hover {
  display: block;
  left: 50%;
  transform: translate(-50%, -100%);
}
.disable-body .disable-icon:hover + .popup-textbox-container .popup-textbox {
  display: block;
}
.popup-textbox-container:hover .popup-textbox {
  display: block;
}
.disable-body:hover .popup-textbox-container .popup-textbox{
  display:block;
}