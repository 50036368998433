@keyframes slideIn {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0%);
	}
}

.notification-container {
	overflow: hidden;
	max-height: 200px;
	transition: max-height 0.3s ease-out;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&-shrink {
		max-height: 0;
	}
}

.create_container-container {
	position: fixed;
	top: 16px;
	right: 16px;
}

.notification {
	max-width: 430px;
	overflow: hidden;
	padding: 12px 48px 12px 12px;
	z-index: 99;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 24px;
	font-weight: bold;
	position: relative;
	transition: transform 0.3s ease-out;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&.info {
		background-color: #2196f3;
	}

	&.success {
		background-color: #4caf50;
	}

	&.warning {
		background-color: #ff9800;
	}

	&.error {
		background-color: #f44336;
	}

	&-slideIn {
		animation-name: slideIn;
		animation-duration: 0.3s;
		animation-timing-function: ease-in-out;
	}

	&-slideOut {
		transform: translateX(150%);
		flex: 0;
	}
}

.notification .closeButton {
	position: absolute;
	top: 12px;
	right: 12px;
	background: transparent;
	padding: 0;
	border: none;
	cursor: pointer;
}

.notification,
.notification .closeButton {
	color: #fff;
}