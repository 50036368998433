* {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
}

.input-wrapper {
  display: flex;
  margin-bottom: 10px;
  border-color: #D1D5DB;

  & input {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    height: 45px;
    margin-right: 10px;
    border-radius: 8px;
    border-color: #69717e;
    border-width: 1px;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }
  }

  & textarea {
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    resize: none;
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.textarea-wrapper {
  height: 200px;
  border-color: #D1D5DB;
  border-width: 1px;
}
.input-wrapper input{
  border-color: #ccc;
}
.submit-wrapper {
  display: flex;

  & input {
    flex: 1;

    &:hover {
      cursor: pointer;
      filter: brightness(85%);
    }
  
    &:active {
      filter: brightness(75%);
    }
    
    &[type=submit] {
      margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    margin-right: 0px;
    font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
    background-color: white;
    color: #071B23;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    width:fit-content;
    border: solid 1.5px #071B23;
    font-size: 13px;
    }
  }

  & .spacer {
    flex: 4;
  }
}