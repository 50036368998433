@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Red+Hat+Text&family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}