.conversation-body {
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.conversation-header {
  height: 60px;
  width: 100%;
  background-color: #898888;
  font-family: 'Roboto', sans-serif;
  color: white;
  border-radius: 13px 13px 0 0;
  z-index: 2;
  position: relative;

  & span {
    display: block;
    margin-left: 30px;
    font-size: 20px;
    line-height: 60px;
    user-select: none;
    font-weight: 500;
  }
}
.conversation-content-body{
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 10px;
  overflow-y: auto;
}

.message-image {
  border-radius: 20px;
  margin-top: 10px;
  
  &.from {
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &.to {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: flex-start;
  }
}
.image-container-guest{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
}
.image-container-user{
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  
}
.loading-ctn{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-label{
  font-size: small;
  color: #999;

}
.media-ctn{
  display: flex;
  justify-content: flex-end;
}
.conversation-messages-row {
  flex-grow: 1;
  overflow-y: auto;
  border-bottom: #edecec 1px solid;
  border-top: #edecec 1px solid;

  & .message-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & .spacer {
      flex-grow: 1;
    }

    & .message {
      border-radius: 15px;
      width: max-content;
      padding: 15px;
      margin-top: 10px;
      max-width: 400px;
      white-space: pre-line;
      font-size: 14px;
      line-height: 18px;
  
      &.from {
        margin-left: 20px;
        color: #FFFFFF;
        background-color: #01A0C6;
      }
  
      &.to {
        margin-right: 20px;
        color: #071B23;
        background-color: #edecec ;
      }
    }
  }
}


.conversation-user-input {
  width: 100%
}

.conversation-input-row {
  box-sizing: border-box;
  height: 45px;
  background-color: #f6f6f6;
  border-radius: 12px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  flex-grow: 1;

  & .input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    line-height: 25px;
    padding: 10px;
    color: #071B23;
    overflow: auto;
    font-family: 'Inter', sans-serif;
    font-size: 13px;

    &::placeholder {
      color: #898888;
    }
  }
}

.conversation-attachment-row{
  display: flex;
  max-height: 100px;
  overflow-y: auto;
  flex-wrap: wrap;

}

.attachment {
  padding: 5px;
  width: fit-content;
  color: #071B23;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  border-radius: 20px;
  margin-bottom: 5px;
  
  & span {
    margin-left: 5px;
  }
}

#attach-input {
  display: none;
}

.conversation-buttons-row {
  height: 70px;
  display: flex;
  background-color: white;

  & input {
    flex-grow: 1;
  }

  & .send-button {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    margin-right: 0px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .4px;
    font-weight: 500;
    font-weight: 700;
    background-color: white;
    color: #071B23;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    width:fit-content;
    border: solid 1.5px #071B23;
    font-size: 13px;

    &:hover {
      cursor: pointer;
      background-color: #fafafa;
    }

    &:active {
      filter: brightness(80%);
    }
  }

  & .attach-button {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    border: none;
    margin-right: 20px;
    background-color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    height: 40px;
    width: 34px;

    &:hover {
      cursor: pointer;
    }
  }
}

.paper-clip-image {
  width: 20px;
  height: 24px;
}

.trash-can-image {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.gray-timestamp {
  margin-left: 10px;
  color: #999;
  font-size: 13px;
  text-align: left;
  padding-bottom: 10px;
}


.blue-timestamp {
  margin-right: 10px;
  color: #999;
  font-size: 13px;
  text-align: right;
  padding-bottom: 10px;
}
.pending-blue-timestamp{
  margin-right: 0px;
  color: #999;
  font-size: 13px;
  text-align: right;
  padding-bottom: 10px;
}
.pending-message-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#pending-message{
  background-color: #0180a6;
  color: #fff;
  margin-left: 20px;
  border-radius: 15px;
  width: max-content;
  padding: 15px;
  margin-top: 10px;
  max-width: 400px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 18px;
}