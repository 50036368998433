
//border-bottom: 2px solid #01a0c6;
//color: #000;
//font-weight: 500;


.table-row{
    display: flex;
    border-bottom: 1px solid #ccc;
    height: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
}
.table-row:hover{
    background-color: whitesmoke;
    cursor: pointer;
    box-shadow: 2px 3px 3px #888;
    transition: .2s;
}
.table-data-point {
    text-align: center;
    color: #777;

    font-weight: 500px;
    font-size: 16px;
    white-space: nowrap;
}
.issued{
    background-color: #dff7e3;
    border-radius: 100px;
    color: #288b37;
}
.not-issued{
    background-color: #fee2e2;
    border-radius: 100px;
    color: #991b1b;
}
.reviews-check-box-container {
    line-height: 20px;
    display: inline-block;
    vertical-align: center;
  }
.reviews-check-box-container input[type=checkbox] {
    accent-color: black;
    
}
.reviews-check-box-container:hover::after {
    position:absolute;
    content: attr(popuptext);
    display: inline-block;
    left: 0px;
    top: 0px;
    padding: 3px;
    background-color: #01A0C6;
    color: white;
    font-size: 10px;
    border-radius: 5px;
}
.question-item {
    display: flex;
    align-items: center;
    margin-right: 25px;
}
/* Adjusting specific columns */
#feedback-checkbox {
    flex: 0.5;
}

#feedback-timestamp{
    flex: 1.5;
    font-size: 12px;
}
#feedback-issued{
    padding: 2px 15px;
    font-size: 12px;
    flex: 0.7;
    margin-left: 50px;
    white-space: nowrap;
} 
#feedback-rating{
    flex: 1.5;
    
}
#feedback-guest{
    flex: 2.5;
    font-weight: 500px;
    color: #000;
    text-align: left;
    margin-left: 10px;
} 

#feedback-feedback {
    flex: 6;
    text-align: left;
}
#questions-container {
    display: flex;
    flex: 6;
    text-align: left;
}
#leads-timestamp{
    flex: 1;
    font-size: 12px;
    align-items: flex-end;
}
#leads-issued{
    padding: 2px 15px;
    font-size: 12px;
    margin-left: 25px;
    white-space: nowrap;
    width: 80px;
    margin-right: 10px;
} 