.arrivals-panel-body {
    flex: 5;
    height: 100%;
    background-color: #fff;
    margin-bottom: 0px;
    display: flex;
}

.arrivals-panel-header {
  padding: 15px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid #ddd;
}

.arrivals-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 90%;
  overflow: auto;
  max-width: 1200px;
  padding-left: 15px;
}

.guest-upload-container {
  flex-direction: row;
  margin-top: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}

.guest-list-container {
  flex-direction:row;
  overflow-y: scroll;
  padding: 0px 5px;
}

.arrivals-panel-title {
  color: #33333D;
  font-family: Helvetica, sans-serif;
  letter-spacing: .4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  display: flex;
  justify-content:flex-start;
}

.add-guest-header {
  font-family: "Inter", sans-serif;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
}

.arrivals-header {
  padding: 15px;
}
.guest-list-body {
  max-width: 1200px;
  padding: 0px 5px;
}
.guest-list-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: auto;
  margin-top: 5px;
  padding-left: 15px;
  align-items:center;
  border-bottom: 2px solid #5c5c5c;

}
.row-header-container{
  height: 20px;
}
.row-header{
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 900;
}
.arrival-entry-row-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.arrival-entry-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 30px;
  align-items: center;
  border-bottom: 1px solid #888;
  padding-left: 15px;
  grid-auto-columns: max-content;
  cursor: pointer;
}
.arrival-entry-row:hover{
  background-color: #fafafa;
  transition: .2s;
  box-shadow: 0px 2px 5px #888;
}
.arrival-label {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 13px;
  letter-spacing: 0.4px;
  font-weight: 500;
  color: rgb(77, 77, 77);
}
.delete-button-container{
  margin-right: 10px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.delete-button{
  border: none;
  background-color: transparent;
  // height: 25px;
  color: #01A0C6;
  font-size: 14px;
  align-items: center;

}
.delete-button:hover{
  cursor: pointer;
  filter: brightness(85%);
}

