.app-body {
  height: 100%;
}

.loading-spinner {
  height: 200px;
  width: 200px;
  display: block;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}