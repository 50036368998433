.inbox-panel-body {
  flex: 5;
  height: 100%;
  background-color: #fff;
  margin-bottom: 0px;
  display: flex;
}

.inbox-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.inbox-panel-header {
  padding: 15px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid #ddd;
}

.conversation-name-row {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #33333D;  
  line-height: 36px; /* 120% */
  letter-spacing: -0.8px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#inbox-panel-title {
  color: #33333D;
  line-height: 36px; /* 120% */
  text-align: center;
  display: flex;
  justify-content:flex-start;
  font-family: Helvetica, sans-serif;
  letter-spacing: .4px;
  font-size: 16px;
  font-weight: 600;

  & .back-button-container {
    &:hover {
      cursor: pointer;
      filter: brightness(85%);
    }
  
    &:active {
      filter: brightness(75%);
    }
  }

  & .back-text {
    color: #9CA3AF;
    font-size: 15px;
  }

  & .manual-switch-label  {
    font-size: 18px;
    padding-right: 10px;
    margin-top: 5px;
    white-space: nowrap;
  }

  & .manual-switch-container {
    //padding-top: 20px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.block-switch-container {
  margin-left: 25px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.block-switch-label {
  font-size: 16px;
  padding-right: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.block-switch-label-text {
  transform: rotate(180deg);
  font-size: 14px;
}

.block-overlay {
  position: absolute;
  width: 80px;
  z-index: 48;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #000;
  color: #fff;
  top: 100%;
  right: 30%;
  padding: 5px;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.block-switch-container .block-overlay {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.block-overlay-text {
  cursor: pointer;
  display: block;
  padding: 2px;
  font-size: 0.3em;
}

.inbox-panel-divider-line {
  background-color: #0000001f;
  height: 1px;
  margin-top: 40px;
  width: 100%;
}

.inbox-container {
  padding: 0px 15px 15px 15px;
  height: 100%;
}

.conversation-container {
  margin-bottom: 50px;
  padding: 0;
}

.back-button {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  vertical-align: middle;
  // padding-bottom: 5px;
  

  &:hover {
    cursor: pointer;
    filter: brightness(85%);
  }

  &:active {
    filter: brightness(75%);
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-top: 5px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  top: 3px;
  bottom: 3px;
  left: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
