
.inbox-body {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
}

.inbox-content-body {
  overflow: auto;
}

.inbox-header {
  height: 60px;
  width: 100%;
  background-color: #898888;
  color: white;
  border-radius: 13px 13px 0 0;
  z-index: 2;
  position: relative;

  & span {
    display: block;
    margin-left: 30px;
    font-size: 20px;
    line-height: 60px;
    user-select: none;
    font-weight: 500;
  }
}

.loading-spinner {
  height: 200px;
  width: 200px;
  display: block;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}