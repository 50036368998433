.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a high z-index value */
}

.modal {
  background: none;
  text-align: center; /* Center the modal content */
  position: relative;
  z-index: 1001; /* Set a higher z-index value than the overlay */
}

.modal-body {
  width: 570px;
  height: fit-content;
  background: rgb(255, 255, 255);
  padding: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  overflow: auto;
  display: inline-block; /* Ensure inline-block for centering */
  position: relative; /* Position relative for absolute positioning of close button */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}