.message-preview-centering-container {
  display: inline-block;
  width: 100%;
}

.message-preview-body {
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  border-bottom: #edecec solid 1px;
  font-size: 14px;


  
  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    box-shadow: 2px 3px 3px #888;
    transition: .2s;
  }

  &:active {
    background-color: #e6e6e6;
  }
}

.guest-conversations-name-container {
  width: 15%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.guest-conversations-phone-container {
  width: 15%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #808790;
}

.guest-conversations-preview-container {
  width: 50%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #808790;
  white-space: nowrap; 
  overflow: hidden; 
}

.guest-conversations-timestamp-container {
  width: 20%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: #808790;
}

.guest-conversations-name {
  margin-left: 15px;
  margin-right: 15px;
  font-style: normal;
  display: inline-block;
}

.guest-conversations-phone-number {
  margin-left: 15px;
  margin-right: 15px;
  line-height: 20px;
  display: inline-block;
  vertical-align: center;
}

.guest-conversations-conversation-preview {
  margin-left: 15px;
  margin-right: 15px;
  line-height: 20px;
  display: inline-block;
  vertical-align: center;
}

.guest-conversations-conversation-timestamp {
  display: inline-block;
  align-items:end;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}