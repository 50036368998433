.settings-panel-body {
  flex: 5;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
}

.settings-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.settings-conversation-container {
  height: 100%;
  margin-top: 20px;
  flex-grow: 1;
  overflow: auto; /* Enable scrolling if content exceeds available height */
  display: flex;
  flex-direction: column;
}

.admin-container {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  align-items: center;
}
.admin-option{
  width: 150px;
  padding: 9px 10px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
  margin-top: 10px;
}
.settings-admin-row {
  display: flex;
  width: 80%; /* Adjust based on layout needs */
  justify-content: space-between; /* Keeps items spaced on the main axis */
  padding: 10px; /* Adjust padding as needed */
  align-items: center; /* Centers items vertically in the row */
}
.just-switch-container, .settings-delete-button {
  display: flex;
  align-items: center; /* Ensures contents of these divs are also centered */
}

.admin-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.escalations-dashboard{
  display: flex;
  padding: 5px 25px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.escalation-form-content{
  display: flex;
  flex-direction: column;
}
.escalation-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.gm-contact-info-label{
  border-bottom: 2px solid #01a0c6;
  padding: 0px 10px;
  text-align: center;
  margin-bottom: 10px;
}
.gm-info-content{
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  border-radius: 8px;
  box-shadow: 1px 2px 3px #999;
  color: #555;
  width: 90%;
  max-width: 500px;
}
.gm-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.gm-info-key{
  text-align: left;
}
.gm-info-value{
  text-align: left;
}
.escalation-toggle-switch-ctn{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 350px;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 20px;
}
.notifications-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.gm-escalation-time-ctn{
  display: flex;
  align-items: center;
  width: 350px;
  justify-content: space-between;
  margin-top: 40px;
}
.escalation-label{
  margin-top: 5px;
}
.gm-email-ctn{
  margin-top: 20px;
}
.admin-contact-info-label{
  border-bottom: 2px solid #01a0c6;
  padding: 0px 10px;
  text-align: center;
  padding-bottom: 5px;
}
.admin-info-content{
  background-color: #fafafa;
  padding-top: 10px;
  border-radius: 8px;
  box-shadow: 1px 2px 3px #999;
  color: #555;
  width: 100%;
  max-width: 500px;
  max-height: 615px;
  overflow-y: auto;
  height: auto;
}
.admin-notification-table{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 5px 10px 10px 10px;
  background-color: #ffffff;
}
.admin-notification-table:nth-child(2n){
  background-color: #fafafa;
}
.gm-email-content{
  border-left: 2px solid #808080;
  padding: 5px 10px;
}
#gm-email-input{
  margin-top: 10px;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #bbb;
  width: 300px;
}
#escalation-times{
  padding: 9px 10px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
}
.escalations-save-button-ctn{
  margin-bottom: 50px;
}
.escalations-save-button{
  background-color: #01A0C6;
  color: white;
  width: 150px;
  text-align: center;
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  border: 1px solid #01a0c6;
}
.escalations-save-button:hover{
  background-color: #0190b6;
}
.settings-panel-header {
  padding: 15px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid #ddd;
}

#settings-panel-title {
  color: #33333D;
  line-height: 36px; /* 120% */
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content:flex-start;

}


.settings-spacing {
  margin-right: 20px;
}


.settings-header {
  height: 60px;
  width: 100%;
  background-color: #898888;
  color: white;
  border-radius: 13px 13px 0 0;
  position: relative;
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-size: 16px;
  font-weight: 600;

  & span {
    display: block;
    margin-left: 30px;
    line-height: 60px;
    user-select: none;
  }
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
#delete-conversations-button{
  background-color: white;
  color: #071B23;
  border: 1.5px solid #071B23;
}
#delete-conversations-button:hover{
  filter: brightness(90%);
  transition: filter .15s;
}
.settings-buttons-row {
  height: 80px;
  margin-left: 25px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
  & .spacer {
    flex-grow: 1;
  }

  & .send-button {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    margin-right: 0px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .4px;
    font-weight: 500;
    background-color: #01A0C6;
    color: #fff;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    width:fit-content;
    border: solid 1.5px #01a0c6;
    font-size: 13px;

    &:hover {
      cursor: pointer;
    }

    &:active {
      filter: brightness(80%);
    }
  }

  & .attach-button {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    border: none;
    border-radius: 12px;
    margin-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: .4px;
    font-weight: 500;
    background-color: #01A0C6;
    color: white;
    height: 40px;
    width: 40px;

    &:hover {
      cursor: pointer;
    }

    &:active {
      filter: brightness(80%);
    }
  }
}

.just-switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 0;
    flex-grow: 1;
    flex-basis: calc(50% - 20px);
}

.switch {
  margin-right: 35px;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(42, 42, 42, 0.5);
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative; // This is to position the close button absolutely.
}

.modal-header {
  display: flex; // Aligns items in a row.
  justify-content: space-between; // Places items at opposite ends.
  align-items: start; // Centers items vertically.
  font-size: 12px;
}

.close {
  align-self: flex-start;
  font-size: 25px;
  color: #aaa;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.settings-input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #edecec;
}

.submit-button {
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 12px;
  margin-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  background-color: #01A0C6;
  color: white;
  height: 40px;
  width: 120px;
  border: none;


  &:hover {
    cursor: pointer;
    background-color: #0190b2;
  }

  &:active {
    filter: brightness(80%);
  }
}

.cancel-button {
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 12px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  background: transparent;
  border: none;
  color: black;
  height: 40px;
  cursor: pointer;

  &:hover {
    color: darkgray;
  }

  &:active {
    color: lightgray;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-header {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
}

.settings-admin-name {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  font-size: 15px;
}

.settings-delete-button {
  width: 15px;
  height: 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.settings-delete-button .dots {
  font-size: x-large;
  border-radius: 50%;
  display: inline-block;
}
.delete-overlay-container{
  position: relative;
}
.delete-overlay {
  width: 100px; // adjust as needed
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
  display: block; // keep this to toggle visibility
  position: absolute;
  top: 25px;
  right: 0px;

}

.delete-overlay p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.sign-out-button {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-size: 14px;
  font-weight: 500;
  color: #071B23;
  width: 100px;
}
.settings-tab-nav{
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
}
.settings-tab-button{
  cursor: pointer;
  font-size: 16px;
  color: #555;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;
}
.settings-tab-button.active{
  border-bottom: 2px solid #01a0c6;
  color: #000;
}