:root {
	--pagination__primary: #01a0c6;
	--pagination__greyDark: #808790;
	--pagination__mutedColor: #808790;
	--pagination__borderColor: #f0f0ec;
	--pagination__colorGray: #808790;
}

.pagination-muted {
	color: var(--pagination__mutedColor);
	font-size: 14px;
	margin-right: 20px;
}

.pagination-container {
	display: flex;
	justify-content:end;
	align-items: center;
	color: var(--pagination__greyDark);
	margin-right: 30px;
}

.pagination-list {
	padding: 0;
	box-sizing: inherit;
	list-style-type: none;
	display: flex;
	align-items: center;
	height: 1.9rem;
	border-radius: 8px;
	border: 2px solid var(--pagination__borderColor);

	&__numbers,
	&__arrow,
	&__dots {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0.4rem;
		font-size: 14px;
		cursor: pointer;
	}

	&__dots {
		width: 2rem;
		height: 2rem;
		color: var(--pagination__mutedColor);
		cursor: initial;
	}

	&__numbers {
		width: 2rem;
		height: 2rem;

		&:hover {
			color: var(--pagination__primary);
		}

		&.active {
			color: var(--pagination__primary);
			font-weight: 600;
			border: 2px solid var(--pagination__primary);
		}
	}

	&__arrow {
		font-size: 14px;
		color: var(--pagination__colorGray);

		.floating-bar {
			border-left: 2px solid var(--pagination__borderColor);
			height: 25px;
			margin: 0 1rem;
		}

		&.disabled {
			cursor: not-allowed;
			color: var(--greyLight-2);
		}

		&.active {
			color: var(--pagination__greyDark);
			pointer-events: initial;
		}

		&:hover {
			color: var(--pagination__primary);
		}
	}
}
