.guest-request-container {
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  border-bottom: #edecec solid 1px;
  font-size: 14px;

  &:hover {
    cursor:pointer;
    background-color: #fafafa;

  }
}

.guest-request-centering-container {
  display: inline-block;
  width: 100%;
  &:hover{
    box-shadow: 0px 3px 3px #bbb;
    transition: .2s;
  }
}

.guest-request-name-container {
  height: 100%;
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guest-request-message-guest-button-container {
  height: 100%;
  display: inline-block;
  align-items: end;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guest-request-name {
  margin-left: 15px;
  margin-right: 15px;
  line-height: 20px;
  display: inline-block;
  vertical-align: center;
  color: #33333D;
}

.guest-request-text-container {
  text-align: left;
  line-height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  max-height: 200px;
  flex: 8;
  color: #808790;
  margin-left: 10px;
}
.requests-checkbox-ctn{
  position: relative;
}

.request-time-container {
  text-align: left;
  line-height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: .4px;
  font-weight: 500;
  max-height: 200px;
  flex: 2;
  color: #808790;

  & .request-time {
    margin-top: 0px;
    font-size: 14px;
    text-align: right;
  }
}
.guest-request-category-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
}
.guest-request-category{
  width: 20px;
  height: 20px;
  color: #fafafa;
  border-radius: 100%;
  padding: 5px;
  align-items: center;
  text-align: center;
  line-height: 20px;
}
.status-icon-container {
  height: 20px;
  width: 60px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(85%);
  }

  &.complete {
    background-color: #DFF7E3;
  }

  &.incomplete {
    background-color: #FEE2E2;
  }

  &.in-progress {
    background-color: #898888;
  }
}

.status-icon-image {
  height: 10px;
  width: 5px;

  &.incomplete {
    width: 15px;
  }
}

.status-menu {
  background-color: #071B23;
  width: 300px;
  height: 200px;
  position: absolute;
  top: 0px;
  right: -25px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }
}

.status-icon-menu-background {
  height: 30px;
  width: 30px;
  // margin-left: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.complete {
    background-color: #DFF7E3;
  }

  &.incomplete {
    background-color: #FEE2E2;
  }

  &.in-progress {
    background-color: #898888;
  }
}

.status-icon-text {
  font-family:Arial, Helvetica, sans-serif;;
  font-size: 12px;
  color: #4d4d4d;

  &.complete {
    color: #fff;
  }

  &.incomplete {
    color: #01A0C6;
  }
}

.status-menu-text {
  padding-left: 0px;
  user-select: none;
}

.menu-row-container {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(85%);
  }

  &.divider-line {
    border-bottom-color: white;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

.check-box-container {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 22px;
  margin-bottom: 22px;
  line-height: 20px;
  display: inline-block;
  vertical-align: center;
}

.check-box-container input[type=checkbox] {
  accent-color: black;
}

.check-box-container:hover::after {
  position:absolute;
  content: attr(popuptext);
  left: 15px;
  top: 40px;
  padding: 3px;
  background-color: #01A0C6;
  color: white;
  font-size: 10px;
  border-radius: 5px;
  width: 80px;
  text-align: center;
}

#check-box {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: center;
}

.reminder-toast {
  color: white;
  background-color: #CF4343;
  font-family: 'Roboto', sans-serif;

  &:hover {
    cursor: pointer;
  }
}