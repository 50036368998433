.reviews-panel-body {
  flex: 5;
  height: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  display:flex;
}

.reviews-panel-header {
  padding: 15px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid #ddd;
}

.reviews-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  flex: 1;

  flex-grow: 1;

  overflow: auto;
}

.reviews-panel-title {
  color: #33333D;
  align-items: center;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 36px; /* 120% */
  letter-spacing: 0.4px;
  text-align: center;
  display: flex;
  justify-content:flex-start;
}

.reviews-button-row {
  display: flex;
  margin-top: 30px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.reviews-menu-button {
  margin-right: 1px;
  cursor: pointer;

  color: #777;
  font-weight: 500px;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;

}
.feedback-menu-button{
  cursor: pointer;
  color: #777;
  font-weight: 500px;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;

}
.reviews-menu-button.active, .feedback-menu-button.active{
  border-bottom: 2px solid #01a0c6;
  color: #000;
  font-weight: 500;
}
.reviews-snapshot-container {
  display: flex;
  margin-top: 15px;
  max-width: 470px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.press-me{
  max-width: 100px;
}

.business-list-container{
  margin: 10px 0px 0px 20px;
  padding: 10px;
  max-width: max-content;
  border-radius: 10px;
}