.entry-disabled{
    cursor: not-allowed;
}
.blast-input-wrapper {
    display: flex;
    margin-bottom: 10px;
    border-color: #D1D5DB;
    align-items: center;
  
    & input {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
      height: 45px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 8px;
      border-color: #69717e;
      border-width: 1px;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }
      &:first-child{
        margin-left: 0;
      }
    }
  
    & textarea {
      padding: 12px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
      resize: none;
      border-radius: 8px;
      box-sizing: border-box;
    }
}
.blast-input-wrapper input{
    border-color: #ccc;
}
.blast-send-button{
    background-color: #01a0c6;
}
#blast-send-button.blast-send-button.send-button-disabled{
    background-color: #0183a2;
}
#blast-send-button.blast-send-button.send-button-disabled:hover{
    cursor: not-allowed; 
}
#blast-send-button{
    background-color: #01a0c6;
    color: white;
    border: none;
}
#blast-send-button:hover{
    background-color: #01a0c6;
    cursor: pointer;
}

.blast-submit-wrapper {
    display: flex;
    & hover {
        cursor: pointer;
    }
    & input {
      flex: 1;
      
      &[type=submit] {
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 10px;
        margin-right: 0px;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: .4px;
        font-weight: 500;
        background-color: white;
        color: #071B23;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        width:fit-content;
        border: solid 1.5px #071B23;
        font-size: 13px;
      }
    }
  
    & .spacer {
      flex: 4;
    }
}
.or-label{
  color: #999;
}
.entry-disabled{
    cursor: not-allowed;
}
.blast-input-wrapper {
    display: flex;
    margin-bottom: 10px;
    border-color: #D1D5DB;
    align-items: center;

  
    & input {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
      height: 45px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 8px;
      border-color: #69717e;
      border-width: 1px;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }
      &:first-child{
        margin-left: 0;
      }
    }
  
    & textarea {
      padding: 12px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
      resize: none;
      border-radius: 8px;
      box-sizing: border-box;
    }
}
.blast-input-wrapper input{
    border-color: #ccc;
}
.all-in-house-button-on{
  background-color: #01a0c6;
  color: #fff;
  cursor: pointer;
  transition: .3s;
}
.all-in-house-button-off{
  background-color: white;
  color: black;
  cursor: pointer;
  transition: .3s;
}
.blast-send-button{
    background-color: #01a0c6;
}
#blast-send-button.blast-send-button.send-button-disabled{
    background-color: #0183a2;
}
#blast-send-button.blast-send-button.send-button-disabled:hover{
    cursor: not-allowed; 
}
#blast-send-button{
    background-color: #01a0c6;
    color: white;
    border: none;
}
#blast-send-button:hover{
    background-color: #01a0c6;
    cursor: pointer;
}

.blast-submit-wrapper {
    display: flex;
    & hover {
        cursor: pointer;
    }
    & input {
      flex: 1;
      
      &[type=submit] {
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 10px;
        margin-right: 0px;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: .4px;
        font-weight: 500;
        background-color: white;
        color: #071B23;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        width:fit-content;
        border: solid 1.5px #071B23;
        font-size: 13px;
      }
    }
  
    & .spacer {
      flex: 4;
    }

  }
  .blast-input-wrapper input{
    border-color: #ccc;
  }