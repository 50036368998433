.leads-panel-body {
    flex: 5;
    height: 100%;
    background-color: #fff;
    margin-bottom: 20px;
    display:flex;
    min-width: none;
  }
  
.leads-panel-content {
  margin: 40px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
.leads-panel-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.leads-panel-title {
  color: #33333D;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 120% */
  letter-spacing: -0.8px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.leads-label-row{
  display: flex;
  margin-top: 30px;
  font-size: 14px;
}
.leads-label {
  margin-right: 1px;
  cursor: default;
  color: #777;
  font-weight: bolder;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;
}
.lead-form-button.active{
  border-bottom: 2px solid #01a0c6;
  color: #000;
  font-weight: bolder;
}
.form-tab-ctn{
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.form-tab{
  color: #777;
  margin: 30px 10px 0px 10px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
}
.form-tab.selected{
  border-bottom: 2px solid #01a0c6;
  color: #000;
  font-weight: 500;
}
.export-button-container{
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  margin-top: 20px;
}
.export-icon{
  width: 25px;
  height: 25px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 100%;
}
.export-icon:hover{
  background-color: #e0e0e0;
  transition: .2s;
  cursor: pointer;
}
#over-checkbox{
  flex: 0.25;
}
#over-name{
  flex: 2.5;
  margin-left: 5px;
  margin-left: 10px;
  text-align: left;
}
#over-content{
  flex: 6;
}
#over-date{
  flex: 1.8;
}
#over-status{
  flex: 1;
  margin-right: 10px;
}
#no-leads{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  flex-direction: column;
  margin-top: 50px;
}