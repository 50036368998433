.control-panel-body {
  width: 250px;
  flex: 1.2;
  height: 100%;
  background-color: #01A0C6;
  font-family:Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.control-panel-header {
  margin-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100px;
}

#smiling-woman {
  height: 65px;
  width: 139px;
  display: inline-block;
  vertical-align: top;
}

.spacer {
  flex-grow: 1;
}

.nav-button {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 40px;
  border-radius: 6px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: left;

  &:hover {
    cursor: pointer;
  }

  &.is-current-page {
    color: white;
    background-color: #0183A2;

    &:hover {
      cursor: default;
    }

    & span:hover {
      font-weight: 900;
    }
  }
}

.nav-button-icon-container {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.nav-button-disable-icon-container {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-button-icon {
  width: 15px;
  height: 15px;
}

.nav-button-name-container {
  width: auto;
}

.nav-button-name-text {
  font-family: 'Red Hat Text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  user-select: none;

}

.nav-button-icon {
  width: 15px;
  height: 15px;
}
.nav-button-disable-icon {
    width: 20px;
    height: 20px;
}
.nav-button-name-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-button-name-text {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  user-select: none;
}

.control-panel {
  display: inline-block;
  vertical-align: top;
  margin: 20px;
}