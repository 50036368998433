.add-guest-wrapper {
  
    & input {
  
      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }
    
      &:active {
        filter: brightness(80%);
      }
      
      &[type=submit] {
        background-color: #01a0c6;
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 10px;
        margin-right: 0px;
        font-family: Arial, Helvetica, sans-serif;
        letter-spacing: .4px;
        font-weight: 500;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        width:167.5px;
        border: solid 1.5px #01a0c6;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }  
}
#form-content{
  width: fit-content;
}