.snapshot-header {
    display: flex;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 14px;
    color: #33333D;
  }
  
  .snapshot-body {
    display: flex;
    justify-content:left;
    align-items: baseline;
    margin-left: 15px;
  }
  
  .snapshot-text {
    font-size: 20px;
    font-weight:bolder;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    color: #33333D;
  }
  
  .snapshot-text-secondary {
    margin-top: 6px;
    margin-left: 6px;
    font-size: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    color: #33333D;
  }
  
  .snapshot-container{
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 20px;

    width: 150px;
    height: 100px;
  }